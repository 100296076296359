html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: rgb(245, 245, 245);
}
.container {
  margin: 75px auto 0 auto;
  max-width: 700px;
}
.nav-container {
  margin: auto;
}
.nav-container svg {
  color: #37474f; /* blueGrey[800] */
}

a {
  color: rgb(245, 245, 245);
  text-decoration: none;
}
